@tailwind base;
@tailwind components;
@tailwind utilities;


.react-calendar__tile--active {
    background: #247490;
}

.bg-black {
    background: #247490;
}